import loadable from "@loadable/component";
import { allDependSheets } from "../../ssui/types/fields";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeHkrShipSummaryTables: StrictViewType = {
  key: "hkrShipSummaryTables",
  displayName: "HKR-SHIP サマリーテーブル",
  render: ViewRender as any,
  dependSheets: allDependSheets,
  configSchemas: [],
  isDisabledEditHistoryShortcuts: true,
};
