import { ContextualMenuItemType, IContextualMenuItem } from "@fluentui/react";
import { useMemo } from "react";
import { useNavigate } from "react-router";

import { useAppRouteParams } from "../AppRoutes";
import {
  getFileVersionDataExcel,
  getFileVersionDataJSON,
  getFileVersionSchema,
  getFileVersionViews,
} from "../api/files";
import { useAppNotificationManager } from "../components/AppProvider/AppNotificationProvider";

import { useAppOrgsAndInvitedOrgs } from "@/components/AppProvider/AppOrgsAndInvitedOrgsProvider";
import { usePermissions } from "@/components/InOrganizationProvider/PermissionsProvider";
import { useVersionPermission } from "@/hooks/useCollectionPermission";
import { generateId } from "@/utils/generateId";

/**
 * @description 各種データ一覧画面で利用するcontextItemsを生成
 */
export const useContextMenuItemsVersionsMenu = (): IContextualMenuItem[] => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const hasVersionsWritePermission = hasPermission("versions", "write");
  const { organizations } = useAppOrgsAndInvitedOrgs();
  const { organizationId, versionId } = useAppRouteParams();
  const organization = organizations.find(
    (org) => org.org_id === organizationId
  );
  const isDisableEdit = useMemo(() => {
    if (!hasVersionsWritePermission) return true;
    if (organizationId && organization?.is_sheet_enabled === true) return false;
    return true;
  }, [hasVersionsWritePermission, organizationId, organization]);

  return useMemo(
    () => [
      {
        key: "k1",
        text: "新規バージョンを作成",
        onClick: () => {
          navigate(`/organizations/${organizationId}/versions/create`);
        },
        disabled: isDisableEdit,
      },
      {
        key: "k2",
        text: "シートの設定ファイルから新規バージョンを作成",
        onClick: () => {
          navigate(
            `/organizations/${organizationId}/versions/upload_sheet_schema`
          );
        },
        disabled: isDisableEdit,
      },
    ],
    [organizationId, versionId, isDisableEdit]
  );
};

export const useContextMenuItemsSheetsMenu = ({
  isDisabled = false,
}: {
  isDisabled?: boolean;
}): IContextualMenuItem[] => {
  const navigate = useNavigate();
  const { organizationId, versionId } = useAppRouteParams();
  const [hasVersionWritePermission] = useVersionPermission("write");

  const {
    showInfoNotification,
    showSuccessNotification,
    showErrorNotification,
  } = useAppNotificationManager();

  const isDisableEdit = isDisabled || !hasVersionWritePermission;

  return useMemo(
    () => [
      {
        key: "i1",
        text: "新規シートを作成",
        onClick: () =>
          navigate(
            `/organizations/${organizationId}/versions/${versionId}/sheet_create`
          ),
        disabled: isDisableEdit,
      },
      {
        key: "divider_1",
        text: "-",
        itemType: ContextualMenuItemType.Divider,
      },
      {
        key: "i2",
        text: "Excel形式でデータを一括ダウンロード",
        onClick: async () => {
          try {
            showInfoNotification("データのダウンロードを実行中...");
            await getFileVersionDataExcel({
              organizationId,
              versionId,
            });
            showSuccessNotification("データのダウンロードに成功しました");
          } catch (error) {
            showErrorNotification("データのダウンロードに失敗しました", error);
          }
        },
        disabled: isDisabled,
      },
      {
        key: "i3",
        text: "JSON形式でデータを一括ダウンロード",
        onClick: async () => {
          try {
            showInfoNotification("データのダウンロードを実行中...");
            await getFileVersionDataJSON({
              organizationId,
              versionId,
              dataType: "json",
            });
            showSuccessNotification("データのダウンロードに成功しました");
          } catch (error) {
            showErrorNotification("データのダウンロードに失敗しました", error);
          }
        },
        disabled: isDisabled,
      },
      {
        key: "i4",
        text: "YAML形式でシート設定ファイルを一括ダウンロード",
        onClick: async () => {
          try {
            showInfoNotification(
              "シート設定ファイルの一括ダウンロードを実行中..."
            );
            await getFileVersionSchema({
              organizationId,
              versionId,
              dataType: "yaml",
            });
            showSuccessNotification(
              "シート設定ファイルの一括ダウンロードに成功しました"
            );
          } catch (error) {
            showErrorNotification(
              "シート設定ファイルの一括ダウンロードに失敗しました",
              error
            );
          }
        },
        disabled: isDisabled,
      },
      {
        key: "i5",
        text: "データを一括アップロード",
        onClick: () => {
          navigate(
            `/organizations/${organizationId}/versions/${versionId}/upload_sheet_data`
          );
        },
        disabled: isDisableEdit,
      },
    ],
    [
      versionId,
      organizationId,
      hasVersionWritePermission,
      isDisabled,
      isDisableEdit,
    ]
  );
};

export const useContextMenuItemsViewsMenu = ({
  isDisabled = false,
}: {
  isDisabled?: boolean;
}): IContextualMenuItem[] => {
  const navigate = useNavigate();
  const { organizationId, versionId } = useAppRouteParams();
  const [hasVersionWritePermission] = useVersionPermission("write");

  const {
    showInfoNotification,
    showSuccessNotification,
    showErrorNotification,
  } = useAppNotificationManager();
  const isDisableEdit = isDisabled || !hasVersionWritePermission;

  return useMemo(
    () => [
      {
        key: "i1",
        text: "新規ビューを作成",
        onClick: async () => {
          const newViewId = generateId();
          navigate(
            `/organizations/${organizationId}/versions/${versionId}/views/${newViewId}/edit`
          );
        },
        disabled: isDisableEdit,
      },
      {
        key: "divider_1",
        text: "-",
        itemType: ContextualMenuItemType.Divider,
      },
      {
        key: "i2",
        text: "設定ファイルを一括ダウンロード",
        onClick: async () => {
          try {
            showInfoNotification("設定ファイルのダウンロードを実行中...");
            await getFileVersionViews({
              organizationId,
              versionId,
              dataType: "yaml",
            });
            showSuccessNotification("設定ファイルのダウンロードに成功しました");
          } catch (error) {
            showErrorNotification(
              "設定ファイルのダウンロードに失敗しました",
              error
            );
          }
        },
        disabled: isDisabled,
      },
      {
        key: "i3",
        text: "設定ファイルを一括アップロード",
        onClick: () => {
          navigate(
            `/organizations/${organizationId}/versions/${versionId}/upload_views`
          );
        },
        disabled: isDisableEdit,
      },
    ],
    [
      versionId,
      organizationId,
      hasVersionWritePermission,
      isDisabled,
      isDisableEdit,
    ]
  );
};
