import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeExample: StrictViewType = {
  key: "example",
  displayName: "サンプルビュー",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "dataSheet",
      displayName: "データシート",
      dependSheetFields: [
        {
          key: "name",
          displayName: "名前",
          type: "string",
        },
        {
          key: "value",
          displayName: "値",
          type: "integer",
        },
      ],
    },
  ],
  configSchemas: [
    {
      key: "borderColor",
      displayName: "枠色",
      type: "string",
      typeFields: null,
      typeParameters: null,
    },
  ],
  noSwitchLoadingComponentOnChange: true,

  // querySettings: [
  //   {
  //     sheetKey: "dataSheet",
  //     sheetFieldSchemaName: "name",
  //     queryFn: () => {
  //       return [
  //         {
  //           opStr: "==",
  //           value: "test",
  //         },
  //       ];
  //     },
  //   },
  // ],
  // isDisabledEditHistoryShortcuts: true,
};
