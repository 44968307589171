import { setResponsiveMode } from "@fluentui/react";
import { format } from "date-fns";
import { ja } from "date-fns/locale/ja";

import { useGetSheets, useSetSheet } from "../../../../domain/Sheet";
import {
  useGetSheetDataRows,
  useSheetDataRowUtils,
} from "../../../../domain/SheetDataRow";
import {
  useGetSheetFieldSchemas,
  useSetSheetFieldSchema,
} from "../../../../domain/SheetFieldSchema";
import {
  Version,
  checkIsOptimizingFinished,
  useSetVersion,
} from "../../../../domain/Version";
import { View, useGetViews, useSetView } from "../../../../domain/View";
import {
  useGetViewConfigSchemas,
  useSetViewConfigSchema,
} from "../../../../domain/ViewConfigSchema";
import {
  useGetViewDependSheets,
  useSetViewDependSheet,
} from "../../../../domain/ViewDependSheet";
import {
  useGetViewDependSheetFields,
  useSetViewDependSheetField,
} from "../../../../domain/ViewDependSheetField";
import { ManagedTransaction } from "../../../../firebase/firestore";

import { DATETIME_WITH_SECONDS_FORMAT } from "@/config/date";
import { useSetReferredFrom } from "@/domain/ReferredFrom";
import { getSinglyLinkedSheetDataRowList } from "@/helpers/getSinglyLinkedSheetDataRowList";
import { generateId } from "@/utils/generateId";

export const duplicateRecursiveVersionData = async ({
  version,
  duplicateVersionName,
  getSheets,
  getViews,
  getSheetDataRows,
  getSheetFieldSchemas,
  getViewDependSheets,
  getViewConfigSchemas,
  getViewDependSheetFields,
  setVersion,
  setSheet,
  setSheetFieldSchema,
  setView,
  setViewConfigSchema,
  setViewDependSheet,
  setViewDependSheetField,
  setReferredFrom,
  sheetDataRowUtils,
}: {
  version: Version;
  duplicateVersionName: Version["title"];
  getSheets: ReturnType<typeof useGetSheets>;
  getViews: ReturnType<typeof useGetViews>;
  getSheetDataRows: ReturnType<typeof useGetSheetDataRows>;
  getSheetFieldSchemas: ReturnType<typeof useGetSheetFieldSchemas>;
  getViewDependSheets: ReturnType<typeof useGetViewDependSheets>;
  getViewConfigSchemas: ReturnType<typeof useGetViewConfigSchemas>;
  getViewDependSheetFields: ReturnType<typeof useGetViewDependSheetFields>;
  setVersion: ReturnType<typeof useSetVersion>;
  setSheet: ReturnType<typeof useSetSheet>;
  setSheetFieldSchema: ReturnType<typeof useSetSheetFieldSchema>;
  setView: ReturnType<typeof useSetView>;
  setViewConfigSchema: ReturnType<typeof useSetViewConfigSchema>;
  setViewDependSheet: ReturnType<typeof useSetViewDependSheet>;
  setViewDependSheetField: ReturnType<typeof useSetViewDependSheetField>;
  setReferredFrom: ReturnType<typeof useSetReferredFrom>;
  sheetDataRowUtils: ReturnType<typeof useSheetDataRowUtils>;
}) => {
  const newVersionId = generateId();
  const versionId = version.id;
  const sheets = await getSheets({ versionId });
  const views = await getViews({ versionId });

  // 削除対象のバージョン内の、referencing sheetのidをキー、
  // referenced sheetのversionIdとsheetIdを値とするMap
  const referencedSheetsMap = new Map<
    string,
    { versionId: string; sheetId: string }
  >();
  sheets
    .filter(({ referTo }) => !!referTo)
    .forEach(({ id, referTo }) => referencedSheetsMap.set(id, referTo!));

  const allSheetsData = await Promise.all(
    sheets.map(async (sheet) => {
      const sheetId = sheet.id;
      const sheetDataRows = await getSheetDataRows({
        versionId,
        sheetId,
      });
      const sheetFieldSchemas = await getSheetFieldSchemas({
        versionId,
        sheetId,
      });
      return {
        sheet,
        sheetDataRows,
        sheetFieldSchemas,
      };
    })
  );

  const allViewsData = await Promise.all(
    views.map(async (view) => {
      const viewId = view.id;
      const viewDependSheets = await getViewDependSheets({
        versionId,
        viewId,
      });
      const viewConfigSchemas = await getViewConfigSchemas({
        versionId,
        viewId,
      });
      const viewDependSheetFieldsWithId = await Promise.all(
        viewDependSheets.map(async ({ id }) => {
          const viewDependSheetFields = await getViewDependSheetFields({
            versionId,
            viewId,
            dependSheetId: id,
          });
          return { viewDependSheetFields, dependSheetId: id };
        })
      );
      return {
        view,
        viewDependSheets,
        viewConfigSchemas,
        viewDependSheetFieldsWithId,
      };
    })
  );

  const createdAt = format(new Date(), DATETIME_WITH_SECONDS_FORMAT, {
    locale: ja,
  });
  // NOTE: 最適化ステータスは、実行中であれば引き継がない
  // 最適化中バージョンを複製すると、最適化中で永遠にステータスが変わらないため
  const newOptimizeStatus = checkIsOptimizingFinished(version)
    ? version.optimizeStatus
    : null;
  await setVersion(
    {
      ...version,
      id: newVersionId,
      title: duplicateVersionName,
      version: newVersionId,
      createdAt,
      publishedAt: createdAt,
      lastModifiedAt: createdAt,
      optimizeStatus: newOptimizeStatus,
    },
    { versionId: newVersionId },
    {}
  );
  // sheets
  await ManagedTransaction.runTransaction(async (transaction) => {
    for (const sheet of allSheetsData.map(({ sheet }) => sheet)) {
      await setSheet(
        {
          ...sheet,
          firstRowId: null,
        },
        { versionId: newVersionId, sheetId: sheet.id },
        { transaction }
      );
    }

    // 参照先のreferred_fromを新たに追加
    for (const [
      id,
      { versionId: refVersionId, sheetId: refSheetId },
    ] of referencedSheetsMap.entries()) {
      await setReferredFrom(
        {
          id: `${newVersionId}:${id}`,
          versionId: newVersionId,
          sheetId: id,
        },
        {
          versionId: refVersionId,
          sheetId: refSheetId,
        }
      );
    }
  });

  // sheetFieldSchemas
  const sheetFieldSchemas = allSheetsData
    .map(({ sheet: { id: sheetId }, sheetFieldSchemas }) => {
      return sheetFieldSchemas.map((sheetFieldSchema) => ({
        sheetId,
        sheetFieldSchema,
      }));
    })
    .flat();

  await ManagedTransaction.runTransaction(async (transaction) => {
    for (const { sheetFieldSchema, sheetId } of sheetFieldSchemas) {
      await setSheetFieldSchema(
        {
          ...sheetFieldSchema,
        },
        { versionId: newVersionId, sheetId },
        { transaction }
      );
    }
    for (const { sheet, sheetDataRows } of allSheetsData) {
      const updatedDuplicateSheet = {
        ...sheet,
        isSheetQueryOn: false,
      };
      const { rows } = getSinglyLinkedSheetDataRowList(
        updatedDuplicateSheet,
        sheetDataRows
      );
      const rowsWithoutId = rows.map(({ id, ...rest }) => rest);
      await sheetDataRowUtils.addRowsByData(rowsWithoutId, {
        versionId: newVersionId,
        sheetId: sheet.id,
      });
    }
  });

  // views
  await ManagedTransaction.runTransaction(async (transaction) => {
    for (const view of allViewsData.map(({ view }) => view)) {
      await setView(
        {
          ...view,
        },
        { versionId: newVersionId, viewId: view.id },
        { transaction }
      );
    }
  });
  // viewConfigSchemas
  const allViewConfigSchemas = allViewsData
    .map(({ view: { id: viewId }, viewConfigSchemas }) => {
      return viewConfigSchemas.map((viewConfigSchema) => ({
        viewId,
        viewConfigSchema,
      }));
    })
    .flat();
  // viewDependSheets
  const allViewDependSheets = allViewsData
    .map(({ view: { id: viewId }, viewDependSheets }) => {
      return viewDependSheets.map((viewDependSheet) => ({
        viewId,
        viewDependSheet,
      }));
    })
    .flat();
  await ManagedTransaction.runTransaction(async (transaction) => {
    for (const { viewConfigSchema, viewId } of allViewConfigSchemas) {
      await setViewConfigSchema(
        {
          ...viewConfigSchema,
        },
        { versionId: newVersionId, viewId },
        { transaction }
      );
    }
    for (const { viewDependSheet, viewId } of allViewDependSheets) {
      await setViewDependSheet(
        {
          ...viewDependSheet,
        },
        { versionId: newVersionId, viewId },
        { transaction }
      );
    }
  });
  // viewDependSheetField
  const allViewDependSheetFields = allViewsData
    .map(({ view: { id: viewId }, viewDependSheetFieldsWithId }) => {
      const resListFlatten = viewDependSheetFieldsWithId
        .map(({ viewDependSheetFields, dependSheetId }) => {
          const resList = viewDependSheetFields.map((viewDependSheetField) => {
            const res = {
              viewDependSheetField,
              dependSheetId,
              viewId,
            };
            return res;
          });
          return resList;
        })
        .flat();
      return resListFlatten;
    })
    .flat();
  await ManagedTransaction.runTransaction(async (transaction) => {
    for (const {
      viewDependSheetField,
      viewId,
      dependSheetId,
    } of allViewDependSheetFields) {
      await setViewDependSheetField(
        {
          ...viewDependSheetField,
        },
        { versionId: newVersionId, viewId, dependSheetId },
        { transaction }
      );
    }
  });
};
