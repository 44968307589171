import { MAXIMUM_DATA_SIZE_MB } from "@/const";
import { Sheet } from "@/domain/Sheet";
import { OrganizationRaw } from "../../domain/Organization";
import { Version } from "../../domain/Version";
import { View } from "../../domain/View";
import { API_CLIENT } from "../config";
import { DownloadDataTypes, downloadFileFromAPI } from "../helpers";
import { getAPIConfigs } from "../helpers";

const apiUrl = "/files/versions";

// NOTE: ファイルサイズの合計が一定以上になるとエラーを返す
const checkFilesSize = (files: File[]) => {
  let totalSize = 0;
  files.forEach((file) => {
    totalSize += file.size;
  });
  if (totalSize >= MAXIMUM_DATA_SIZE_MB * 1024 * 1024) {
    throw new Error(
      `ファイルサイズが大きすぎます。合計${MAXIMUM_DATA_SIZE_MB}MB未満にしてください。`
    );
  }
};

// Excelファイルアップロード時に選択するクエリ
export type SaveType = "overwrite" | "append";

export type PostSchemaResponse = { org_id: string; version: string };
export type PostDataResponse = {
  org_id: string;
  version: string;
  message: string;
};

export const getFileVersionSchema = async ({
  organizationId,
  versionId,
  dataType,
  fileName,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  dataType: DownloadDataTypes;
  fileName?: string;
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: { ...apiOrganizationIdParams?.params, version: versionId },
  };
  await downloadFileFromAPI(
    `${apiUrl}/schemas`,
    dataType,
    {
      ...apiParams,
    },
    fileName
  );
};

export const getFileVersionDataJSON = async ({
  organizationId,
  versionId,
  fileName,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  dataType: DownloadDataTypes;
  fileName?: string;
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: { ...apiOrganizationIdParams?.params, version: versionId },
  };
  await downloadFileFromAPI(
    `${apiUrl}/data`,
    "json",
    {
      ...apiParams,
    },
    fileName
  );
};

export const getFileVersionDataExcel = async ({
  organizationId,
  versionId,
  fileName,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  fileName?: string;
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: { ...apiOrganizationIdParams?.params, version: versionId },
  };
  await downloadFileFromAPI(
    `${apiUrl}/data/excel`,
    "xlsx",
    {
      ...apiParams,
    },
    fileName
  );
};

export const getFileVersionDataCSV = async ({
  organizationId,
  versionId,
  sheetId,
  fileName,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  sheetId: Sheet["id"];
  fileName?: string;
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: {
      ...apiOrganizationIdParams?.params,
      version: versionId,
      sheet_id: sheetId,
    },
  };
  await downloadFileFromAPI(
    `${apiUrl}/data/csv`,
    "csv",
    {
      ...apiParams,
    },
    fileName
  );
};

export const getFileVersionResults = async ({
  organizationId,
  versionId,
  fileName,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  fileName?: string;
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: { ...apiOrganizationIdParams?.params, version: versionId },
  };
  await downloadFileFromAPI(
    `${apiUrl}/results`,
    "zip",
    {
      ...apiParams,
    },
    fileName
  );
};
export const getFileVersionMultiResults = async ({
  organizationId,
  versionIds,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionIds: Version["id"][];
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: {
      ...apiOrganizationIdParams?.params,
      versions: versionIds.join(","),
    },
  };
  await downloadFileFromAPI(`${apiUrl}/multi_results`, "zip", {
    ...apiParams,
  });
};
export const getFileVersionInputs = async ({
  organizationId,
  versionId,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: { ...apiOrganizationIdParams?.params, version: versionId },
  };
  await downloadFileFromAPI(`${apiUrl}/inputs`, "zip", {
    ...apiParams,
  });
};
export const getFileVersionMultiInputs = async ({
  organizationId,
  versionIds,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionIds: Version["id"][];
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: {
      ...apiOrganizationIdParams?.params,
      versions: versionIds.join(","),
    },
  };
  await downloadFileFromAPI(`${apiUrl}/multi_inputs`, "zip", {
    ...apiParams,
  });
};

// not implemented
export const getFileVersionView = async ({
  organizationId,
  versionId,
  viewId,
  dataType,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  viewId: View["id"];
  dataType: Extract<DownloadDataTypes, "json" | "yaml">;
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: {
      ...apiOrganizationIdParams?.params,
      version: versionId,
      view_id: viewId,
    },
  };
  await downloadFileFromAPI(`${apiUrl}/views`, dataType, {
    ...apiParams,
  });
};
export const getFileVersionViews = async ({
  organizationId,
  versionId,
  dataType,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  dataType: Extract<DownloadDataTypes, "json" | "yaml">;
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: {
      ...apiOrganizationIdParams?.params,
      version: versionId,
    },
  };
  await downloadFileFromAPI(`${apiUrl}/views`, dataType, {
    ...apiParams,
  });
};

// スキーマ設定ファイルをアップロードしてバージョンを作成
// cagetoryIDを投げることで、作成されたバージョンのカテゴリを指定可能
// (初期値は空文字列=「未分類」）
export const postFileVersionSchema = async ({
  organizationId,
  title,
  comment,
  file,
  category,
}: {
  organizationId: OrganizationRaw["org_id"];
  title: string;
  comment: string;
  file: File;
  category: string;
}): Promise<PostDataResponse> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const form = new FormData();
  checkFilesSize([file]);
  form.append("file", file);
  form.append("title", title);
  form.append("comment", comment);
  const response = await API_CLIENT.post<PostDataResponse>(
    `${apiUrl}/schemas`,
    form,
    {
      params: {
        ...apiOrganizationIdParams?.params,
        category,
      },
    }
  );
  return response.data;
};
export const postFileVersionData = async ({
  organizationId,
  versionId,
  file,
  saveType,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  file: File;
  saveType: SaveType;
}): Promise<PostDataResponse> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: {
      ...apiOrganizationIdParams?.params,
      version: versionId,
      save_type: saveType,
    },
  };
  const form = new FormData();
  checkFilesSize([file]);
  form.append("file", file);
  const response = await API_CLIENT.post<PostDataResponse>(
    `${apiUrl}/data`,
    form,
    {
      ...apiParams,
    }
  );
  return response.data;
};

export const postFileVersionDataCSV = async ({
  organizationId,
  versionId,
  file,
  sheetId,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  file: File;
  sheetId: Sheet["id"];
}): Promise<PostDataResponse> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: {
      ...apiOrganizationIdParams?.params,
      version: versionId,
      sheet_id: sheetId,
    },
  };
  const form = new FormData();
  checkFilesSize([file]);
  form.append("file", file);
  const response = await API_CLIENT.post<PostDataResponse>(
    `${apiUrl}/data/csv`,
    form,
    {
      ...apiParams,
    }
  );
  return response.data;
};

// not implemented
export const postFileVersionView = async ({
  organizationId,
  versionId,
  file,
  viewId,
  viewType,
  description,
  title,
  sort,
  is_override,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  viewId: View["id"];
  file: File;
  is_override: boolean;
} & Pick<
  View,
  "description" | "viewType" | "title" | "sort"
>): Promise<PostDataResponse> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: {
      ...apiOrganizationIdParams?.params,
      version: versionId,
      view_id: viewId,
    },
  };
  const form = new FormData();
  checkFilesSize([file]);
  form.append("file", file);
  form.append("description", description);
  form.append("viewType", viewType);
  form.append("title", title);
  form.append("sort", String(sort));
  form.append("is_override", String(is_override));
  const response = await API_CLIENT.post<PostDataResponse>(
    `${apiUrl}/views`,
    form,
    {
      ...apiParams,
    }
  );
  return response.data;
};

export const postFileVersionViews = async ({
  organizationId,
  versionId,
  file,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  file: File;
}): Promise<PostDataResponse> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: { ...apiOrganizationIdParams?.params, version: versionId },
  };
  const form = new FormData();
  checkFilesSize([file]);
  form.append("file", file);
  const response = await API_CLIENT.post<PostDataResponse>(
    `${apiUrl}/views`,
    form,
    {
      ...apiParams,
    }
  );
  return response.data;
};
