import { ViewType } from "@/domain/ViewType";
import { viewTypeHkrShipPlantSchedules } from "./PlantSchedules";
import { viewTypeHkrShipSheets } from "./Sheets";
import { viewTypeHkrShipAllPlantsSummary } from "./ShipGraph/AllPlantsSummary";
import { viewTypeHkrShipGraph } from "./ShipGraph/EachPlant";
import { viewTypeHkrShipSummaryTables } from "./SummaryTables";
import { viewTypeHkrShipTasksByContract } from "./TaskTables/TasksByContract";
import { viewTypeHkrShipTasksByPlant } from "./TaskTables/TasksByPlant";
import { viewTypeHkrShipTasksByShip } from "./TaskTables/TasksByShip";

export const viewTypesHKRShip: ViewType<any, any>[] = [
  viewTypeHkrShipGraph,
  viewTypeHkrShipAllPlantsSummary,
  viewTypeHkrShipPlantSchedules,
  viewTypeHkrShipSheets,
  viewTypeHkrShipTasksByShip,
  viewTypeHkrShipTasksByContract,
  viewTypeHkrShipTasksByPlant,
  viewTypeHkrShipSummaryTables,
];
